import { IRoute, useRoutes } from '@/pages/router'
import { Tag, Text, Tooltip, styled } from '@punto-ui/react'
import { useRouter } from 'next/router'
import { useSideBarContext } from '@/contexts/hooks'
import {
  NavigatorContainer,
  RouteContainer,
  RouterContentContainer,
} from './styles'
import { useDebounce } from '@/hooks'
import Link from 'next/link'
import { Div } from '@/components'
import React from 'react'
import {
  QuestionMarkCircleIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline'

const UnstyledLink = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
})

export const Navigator = () => {
  const { routes, publicRoutes } = useRoutes()

  const { isOpen } = useSideBarContext()
  const debouncedIsOpen = useDebounce(isOpen, 200)

  return (
    <NavigatorContainer>
      {routes.map((route) => (
        <NavigationItem
          route={route}
          isOpen={isOpen}
          debouncedIsOpen={debouncedIsOpen}
          key={route.path}
        />
      ))}
      <Div
        css={{
          height: 1,
          width: '90%',
          margin: '$4 auto',
          background: '$interface_light_down',
        }}
      />
      {publicRoutes.map((route) => (
        <NavigationItem
          route={route}
          isOpen={isOpen}
          debouncedIsOpen={debouncedIsOpen}
          key={route.path}
        />
      ))}
      <Div
        css={{
          height: 1,
          width: '90%',
          margin: '$4 auto',
          background: '$interface_light_down',
        }}
      />
      <NavigationItem
        route={{
          icon: <QuestionMarkCircleIcon />,
          label: 'Ayuda',
          path: '/ayuda',
          disabled: true,
        }}
        isOpen={isOpen}
        debouncedIsOpen={debouncedIsOpen}
      />
      <NavigationItem
        route={{
          icon: <SparklesIcon />,
          label: 'Planes',
          path: '/ayuda',
          disabled: true,
        }}
        isOpen={isOpen}
        debouncedIsOpen={debouncedIsOpen}
      />
    </NavigatorContainer>
  )
}

const NavigationItem = ({
  route,
  isOpen,
  debouncedIsOpen,
}: {
  route: IRoute
  isOpen: boolean
  debouncedIsOpen: boolean
}) => {
  const router = useRouter()
  const isSelected = router.pathname.includes(route.path)

  return (
    <>
      {isOpen ? (
        <UnstyledLink
          href={route.disabled || isSelected ? '' : route.path}
          passHref
          css={{
            opacity: route.disabled ? 0.5 : 1,
          }}
        >
          <RouteContainer isSelected={isSelected}>
            <RouterContentContainer>
              {!!route.icon && route.icon}
              {isOpen && debouncedIsOpen && (
                <Text
                  css={{
                    width: '100%',
                  }}
                  variant="description"
                >
                  {route.label}
                </Text>
              )}
            </RouterContentContainer>
            <Div>
              {route.badge && (
                <Div
                  css={{
                    height: 16,
                    width: 16,
                    borderRadius: '100%',
                    background: '$brand_complementary_pure',
                    whiteSpace: 'nowrap',
                    marginRight: '$2',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    variant="caption"
                    css={{
                      color: '$interface_light_pure !important',
                    }}
                  >
                    {route.badge}
                  </Text>
                </Div>
              )}
            </Div>
            <Div>
              {route.tag && (
                <Tag
                  variant={route.tagVariant}
                  css={{
                    whiteSpace: 'nowrap',
                    marginRight: '$2',
                  }}
                >
                  {route.tag}
                </Tag>
              )}
            </Div>
          </RouteContainer>
        </UnstyledLink>
      ) : (
        <Tooltip key={route.label} arrow message={route.label} side="right">
          <UnstyledLink
            href={route.disabled || isSelected ? '' : route.path}
            passHref
          >
            <Div
              css={{
                opacity: route.disabled ? 0.5 : 1,
              }}
            >
              <RouteContainer isSelected={isSelected}>
                <Div
                  css={{
                    position: 'relative',
                  }}
                >
                  {!!route.icon && route.icon}
                  {route.badge && (
                    <Div
                      css={{
                        position: 'absolute',
                        top: 0,
                        right: -16,
                      }}
                    >
                      <Div
                        css={{
                          height: 16,
                          width: 16,
                          borderRadius: '100%',
                          background: '$brand_complementary_pure',
                          whiteSpace: 'nowrap',
                          marginRight: '$2',

                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text
                          variant="caption"
                          css={{
                            color: '$interface_light_pure !important',
                            fontWeight: '$bold',
                          }}
                        >
                          {route.badge}
                        </Text>
                      </Div>
                    </Div>
                  )}
                </Div>
                {isOpen && debouncedIsOpen && (
                  <Text variant="description">{route.label}</Text>
                )}
              </RouteContainer>
            </Div>
          </UnstyledLink>
        </Tooltip>
      )}
    </>
  )
}
