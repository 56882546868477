import { styled } from '@punto-ui/react'
import { FieldValues } from 'react-hook-form'
import { ControlledDropdown } from '../ControlledDropdown'
import { ControlledInput } from '../ControlledInput'
import { ControlledFileInput } from '../ControlledFileInput'
import { ControlledCheckbox } from '../ControlledCheckbox'
import { ControlledWorkerSearchBar } from '../ControlledWorkerSearchBar'
import { ControlledCalendarInput } from '../ControlledCalendar'
import { ControlledFieldProps } from './types'
import { ControlledSwitch } from '../ControlledSwitch'
import { ControlledChip } from '../ControlledChip'
import { ControlledSalaryConcept } from '../ControlledSalaryConceptOptions'
import { ControlledFullWorkerSearchBar } from '../ControlledFullWorkerSearchBar'
import { ControlledBulletPoints } from '../ControlledBulletPoints'

export type FormRendererProps<TFieldValues extends FieldValues> = {
  items: Array<ControlledFieldProps<TFieldValues>>
  inline?: boolean
  cols?: number
}

export const FormRenderer = <TFieldValues extends FieldValues>({
  items,
  inline,
  cols,
}: FormRendererProps<TFieldValues>) => {
  return (
    <FormRendererContainer inline={inline}>
      {items.map((item) => {
        if (item._type === 'dropdown') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: item.width
                  ? item.width
                  : cols
                  ? `calc((100% / ${cols}) - 2%)`
                  : undefined,
              }}
            >
              <ControlledDropdown {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'bullet-points') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledBulletPoints {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'input') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledInput {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'checkbox') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledCheckbox {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'worker_search_bar') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledWorkerSearchBar {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'full_worker_search_bar') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                maxHeight: item.maxHeight || 500,
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledFullWorkerSearchBar {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'file') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledFileInput {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'calendar') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledCalendarInput {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'switch') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledSwitch {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'chip') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledChip {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'row') {
          return (
            <FormRenderer
              items={item.fields}
              cols={item.numberOfColumns}
              inline
              key="row"
            />
          )
        } else if (item._type === 'salary-concepts') {
          return (
            <ItemContainer
              key={item.name}
              css={{
                width: cols ? `calc((100% / ${cols}) - 2%)` : undefined,
              }}
            >
              <ControlledSalaryConcept key={item.name} {...item} />
            </ItemContainer>
          )
        } else if (item._type === 'custom') {
          return item.component
        } else {
          return <></>
        }
      })}
    </FormRendererContainer>
  )
}

const ItemContainer = styled('div', {})

const FormRendererContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  columnGap: '$4',
  rowGap: '$4',
  width: '100%',

  variants: {
    inline: {
      true: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    },
  },
})
