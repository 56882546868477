import React from 'react'
import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { getDeepVal } from '@/utils/object'
import { ControlledDropdownP } from './types'
import { DropdownV2 } from '@/components/DropdownV2'

export const ControlledDropdown = <TFieldValues extends FieldValues>(
  props: ControlledDropdownP<TFieldValues>,
) => {
  const {
    control,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useFormContext()

  // const {
  //   field: { onChange, value },
  // } = useController({
  //   name: props.name,
  //   control,
  //   defaultValue: getValues(props.name),
  // })

  const value = useWatch({
    name: props.name,
    control,
    defaultValue: getValues(props.name),
  })

  const onValueChange = (s: string, index?: number) => {
    setValue(props.name, s as any)
    props.onValueChange?.(s, index)
  }

  const inputError = getDeepVal(errors, props.name)

  return (
    <DropdownV2
      {...props}
      value={value}
      onChangeValue={async (s) => {
        onValueChange(s.value)
        await trigger(props.name)
      }}
      errorMessage={inputError ? inputError.message : undefined}
      defaultValue={value}
    />
  )
}
