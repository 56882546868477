import React, { ElementRef, forwardRef } from 'react'

import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { styled } from '../../styles'
import { Text, TextInputProps, Tooltip, TooltipProvider } from '@punto-ui/react'

const Div = styled('div')
export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  background: 'transparent',
})

export const TextInputContainer = styled('div', {
  borderRadius: '$xs',
  border: '1px solid $interface_light_down',
  boxSizing: 'border-box',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',

  height: 20,

  // -webkit-transition : border 500ms ease-out;
  // -moz-transition : border 500ms ease-out;
  // -o-transition : border 500ms ease-out;
  // transition : border 500ms ease-out;

  '-webkit-transition': 'border 250ms ease-out',
  '-moz-transition': 'border 250ms ease-out',
  '-o-transition': 'border 250ms ease-out',
  transition: 'border 250ms ease-out',

  '::placeholder': {
    color: '$interface_dark_up',
  },

  '&:focus': {
    borderColor: '$brand_primary_pure',
  },

  '& svg': {
    height: '$4',
    width: '$4',
    strokeWidth: 2,
    color: '$interface_dark_up',
  },

  '&:has(input:focus)': {
    borderColor: '$brand_primary_pure',

    '& svg': {
      color: '$brand_primary_pure',
    },
  },

  '&:has(input:disabled)': {
    opacity: 0.5,
    background: '$interface_light_down',
    cursor: 'not-allowed',

    '& svg': {
      color: '$interface_dark_up',
    },
  },
})

export const Input = styled('input', {
  backgroundColor: 'transparent',
  minHeight: 20,
  paddingLeft: '$2',
  fontFamily: '$default',
  fontSize: '$xs',
  border: 0,
  width: '100%',
  color: '$interface_dark_down',

  lineHeight: '120%',
  fontWeight: 600,

  '&:focus': {
    outline: 0,
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '&:placeholder': {
    color: '$interface_dark_up',
  },
})

export const ModernTextInput = forwardRef<
  ElementRef<typeof Input>,
  TextInputProps
>(({ icon, errorMessage, disclaimer, help, ...props }: TextInputProps, ref) => {
  return (
    <TooltipProvider>
      <Container>
        {props.label && (
          <Text
            variant={'caption'}
            css={{ color: '$interface_dark_deep', fontWeight: '$normal' }}
          >
            {props.label}
          </Text>
        )}
        <TextInputContainer
          css={{
            ...props.css,
            paddingRight: '$2',
            position: 'relative',
            background: 'rgba(246, 247, 246, 0.5)',
            flex: 1,
            borderColor: errorMessage
              ? '$status_danger_deep'
              : '$interface_light_down',
            '& svg': {
              color: errorMessage
                ? '$status_danger_deep'
                : '$interface_dark_up',
            },
          }}
        >
          <Input {...props} ref={ref} />
          {!!help && (
            <Tooltip message={help} arrow>
              <Div
                css={{
                  position: 'absolute',
                  // background: 'red',
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: icon ? 32 : 12,
                }}
              >
                <QuestionMarkCircleIcon />
              </Div>
            </Tooltip>
          )}
          {!!errorMessage && (
            <Tooltip message={errorMessage} arrow>
              <Div
                css={{
                  position: 'absolute',
                  // background: 'red',
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: icon ? 32 : 12,

                  svg: {
                    height: 16,
                    width: 16,
                    strokeWidth: 2,
                    color: '$status_danger_pure',
                  },
                }}
              >
                <ExclamationTriangleIcon />
              </Div>
            </Tooltip>
          )}
          {!!icon && icon}
        </TextInputContainer>
        {/* {errorMessage && (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              svg: {
                height: 20,
                width: 20,
                fill: '$status_danger_pure',
                strokeWidth: 2,
                color: '$interface_light_pure',
              },
              gap: '$1',
            }}
          >
            <ExclamationTriangleIcon />
            <Text
              size="xs"
              as="label"
              variant="caption"
              css={{ color: '$status_danger_pure' }}
            >
              {errorMessage}
            </Text>
          </Div>
        )} */}
        {/* {!!disclaimer && !errorMessage && (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$1',
            }}
          >
            <Text
              size="xs"
              as="label"
              variant="caption"
              css={{ color: '$interface_dark_up', fontWeight: 600 }}
            >
              {disclaimer}
            </Text>
          </Div>
        )} */}
      </Container>
    </TooltipProvider>
  )
})

ModernTextInput.displayName = 'ModernTextInput'
