import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IMovementContextProps, IPaginatedMovements } from '../../types'
import { useMe } from '../useMe'

const fetchMovements = async ({ ...props }: IMovementContextProps) => {
  const response = await apiV2.post<IPaginatedMovements>(
    '/movement/get-movements',
    {
      types: props.types.length === 0 ? undefined : props.types,
      periodStart: props.startDate,
      periodEnd: props.endDate,
      name: props.name,
      page: props.page,
      categories: props.categories?.length === 0 ? undefined : props.categories,
      perPage: props.pageSize,
      status: props.status,
    },
    {
      params: {
        cellsIds: props.cellsIds?.join(','),
      },
    },
  )

  return response.data
}

export const useMovements = (props: IMovementContextProps) => {
  const { data: me } = useMe()

  const query = useQuery(
    ['movements', me?.company_id, props],
    () =>
      fetchMovements({
        ...props,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      enabled: !!me && !!me?.company_id && !!props.cellsIds?.length,
    },
  )
  return query
}
