import { Button, Tabs, Tag } from '@punto-ui/react'
import { IDefaultDrawerProps } from '../types'
import { Div } from '@/components/Div'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ExportTemplateSchema, ExportTemplateSchemaType } from './form'
import { ExportTemplateBasicConcepts } from './ExportTemplateBasicConcepts'
import { ExportTemplateFilters } from './ExportTemplateFilters'
import { useEffect } from 'react'
import { useHandleRequestExportTemplate } from '@/libs/react-query/mutations/reports/useHandleRequestExportTemplate'
import useToast from '@/hooks/useToast'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'
import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import dayjs from 'dayjs'
import { reportBuilderFilterTypes } from '@/pages/reportes-v2/ReportBuilder/constants/report-builder-filter-types'
import { IReportBuilderColumnFilterTypes } from '@/pages/reportes-v2/ReportBuilder/filter-types'
import { Drawer, DrawerContainer } from '@/components/Drawer'

export const ExportTemplateDrawer = (
  props: IDefaultDrawerProps & {
    templateId: string
    onClickExport?: () => void
  },
) => {
  const { data: template } = useReportTemplate(props.templateId)

  const toast = useToast()

  const methods = useForm<ExportTemplateSchemaType>({
    resolver: zodResolver(ExportTemplateSchema),
    defaultValues: {
      dates: [],
      day: [],
      // month: '0',
      payrollIds: [],
      year: dayjs().year().toString(),
      filters: [],
      usersIds: [],
      aguinaldoIds: [],
    },
  })

  const [filters] = useWatch({
    control: methods.control,
    name: ['filters'],
  })

  useEffect(() => {
    if (!props.templateId) {
      methods.reset({
        dates: [],
        day: [],
        // month: '0',
        payrollIds: [],
        year: dayjs().year().toString(),
        filters: [],
        usersIds: [],
        aguinaldoIds: [],
      })
    }

    const values = methods.getValues()

    if (template && !values.filters.length) {
      methods.setValue(
        'filters',
        template.filters.map((f) => {
          const filtersOperators =
            reportBuilderFilterTypes[
              (f.valueType as IReportBuilderColumnFilterTypes) || 'string'
            ]
          const operationsOptions = filtersOperators.operations.map((f) => ({
            label: f.label,
            value: f.value,
            requiredInput: f.requiredInput,
          }))

          const selectedOperationHasValue = !!operationsOptions.find(
            (c) => c.value === f.condition,
          )?.requiredInput

          return {
            id: (Date.now() + Math.random() * 100).toString(),
            column: `${f.type}.${f.name}`,
            type: f.type,
            columnType: f.valueType,
            condition: f.condition,

            hasValue: selectedOperationHasValue !== null,

            value: f.value ? f.value : '',
            dates: f.date ? [dayjs(f.date).toDate()] : [],

            options: operationsOptions,
          }
        }),
      )
    }
  }, [props.templateId, methods, template])

  const {
    mutateAsync: handleExportTemplate,
    isLoading: isLoadingExportTemplate,
  } = useHandleRequestExportTemplate()

  const tabs = [
    {
      label: 'Informaciones basicas',
      content: <ExportTemplateBasicConcepts templateId={props.templateId} />,
    },
  ]

  if (!template?.id.includes('default') && !template?.id.includes('fixed')) {
    tabs.push({
      label: 'Filtros',
      content: <ExportTemplateFilters templateId={props.templateId} />,
    })
  }

  const completeFilters = filters.filter(
    (f) => (f.value || f.dates || !f.hasValue) && f.condition && f.column,
  ).length
  const incompleteFilters = !!filters.filter(
    (f) => (!f.value && !f.dates && f.hasValue) || !f.condition || !f.column,
  ).length

  return (
    <FormProvider {...methods}>
      <DrawerContainer open={props.isOpen} onOpenChange={props.setIsOpen}>
        <Drawer
          onOpenChange={props.setIsOpen}
          open={props.isOpen}
          dialog
          overlayCss={{
            zIndex: 110,
          }}
          containerCss={{
            zIndex: 111,
            overflow: 'hidden',
            maxHeight: '80vh',
            padding: 0,
          }}
          content={
            <Div
              css={{
                position: 'relative',
                minWidth: '66vw',
                maxHeight: '80vh',
                overflow: 'hidden',

                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Div
                css={{
                  width: '100%',
                  minHeight: 36,
                  background: '$interface_light_up',
                  borderTopRightRadius: '$md',
                  borderTopLeftRadius: '$md',
                }}
              />
              {tabs.length > 1 ? (
                <Tabs tabs={tabs} />
              ) : (
                <ExportTemplateBasicConcepts templateId={props.templateId} />
              )}
              <Div
                css={{
                  alignSelf: 'center',
                  height: 50,

                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                  background: 'transparent',
                }}
              >
                <Button
                  disabled={isLoadingExportTemplate}
                  isLoading={isLoadingExportTemplate}
                  icon={<CloudArrowDownIcon />}
                  onClick={
                    props.onClickExport
                      ? props.onClickExport
                      : () => {
                          const values = methods.getValues()

                          // console.log('values', values)
                          // return

                          handleExportTemplate({
                            template_id: props.templateId,
                            users_ids: values.usersIds,
                            payrollIds: values.payrollIds,
                            aguinaldoIds: values.aguinaldoIds,
                            date: {
                              startDate: values.dates?.[0]?.toISOString(),
                              endDate: values.dates?.[1]?.toISOString(),
                              day: values.day?.[0]?.toISOString(),
                              // month: values.month,
                              year: values.year,
                            },
                            filters: values.filters
                              .filter(
                                (f) =>
                                  f.value !== '' ||
                                  f.dates.filter((c) => !!c).length > 0 ||
                                  !f.hasValue,
                              )
                              .map((f) => ({
                                name: f.column.split('.')[1],
                                type: f.column.split('.')[0],
                                valueType: f.columnType,
                                condition: f.condition,
                                value: String(f.value),
                                date: f.dates[0] ? f.dates[0] : undefined,
                              })),
                          })

                          const toastId = Date.now()

                          toast.addToast({
                            title: 'Reporte solicitado',
                            description:
                              'El reporte se esta generando, va al modulo de reportes para conferir su estado.',
                            type: 'positive',
                            timeout: 12000,
                            id: toastId,
                            buttonLabel: 'Cerrar',
                            callback: () => {
                              toast.removeToast(toastId)
                            },
                          })
                        }
                  }
                >
                  Exportar reporte
                </Button>

                {!!completeFilters && (
                  <Tag
                    css={{
                      marginLeft: '$2',
                      height: 32,

                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '$2',
                      paddingRight: '$2',
                    }}
                  >
                    +{completeFilters} filtro
                    {filters.filter((f) => f.value && f.condition && f.column)
                      .length > 1
                      ? 's'
                      : ''}{' '}
                    aplicado
                    {filters.filter((f) => f.value && f.condition && f.column)
                      .length > 1
                      ? 's'
                      : ''}
                  </Tag>
                )}
                {incompleteFilters && (
                  <Tag
                    variant="warning"
                    css={{
                      marginLeft: '$2',
                      height: 32,

                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '$2',
                      paddingRight: '$2',
                    }}
                  >
                    {incompleteFilters} filtro
                    {filters.filter(
                      (f) => !f.value || !f.condition || !f.column,
                    ).length > 1
                      ? 's'
                      : ''}{' '}
                    no configurado
                    {filters.filter(
                      (f) => !f.value || !f.condition || !f.column,
                    ).length > 1
                      ? 's'
                      : ''}
                  </Tag>
                )}
              </Div>
            </Div>
          }
        />
      </DrawerContainer>
    </FormProvider>
  )
}
