import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Text, styled } from '@punto-ui/react'
import { months } from '@/utils/date'
import { useOutsideAlerter } from '@/hooks'

const Touchable = styled('button', {
  all: 'unset',
  cursor: 'pointer',
})

export const Filters = (props: {
  setCalendarDate: React.Dispatch<React.SetStateAction<Date>>
  calendarDate: Date
}) => {
  const allYearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const last10Years = Array.from(
      { length: 10 },
      (_, i) => currentYear - i - 1,
    )
    const next10Years = Array.from(
      { length: 10 },
      (_, i) => currentYear + i + 1,
    )

    const allYears = [
      ...last10Years.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
      {
        value: currentYear.toString(),
        label: currentYear.toString(),
      },
      ...next10Years.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
    ]

    allYears.sort((a, b) => Number(a.value) - Number(b.value))

    return allYears
  }, [])

  const [isMonthFilterOpen, setIsMonthFilterOpen] = useState(false)
  const [isYearFilterOpen, setIsYearFilterOpen] = useState(false)

  const monthContainerRef = useRef<HTMLDivElement>(null)
  const yearContainerRef = useRef<HTMLDivElement>(null)

  useOutsideAlerter(monthContainerRef, () => setIsMonthFilterOpen(false))
  useOutsideAlerter(yearContainerRef, () => setIsYearFilterOpen(false))

  return (
    <FiltersContainer>
      <Filter>
        <IconButton
          type="button"
          onClick={() => {
            props.setCalendarDate((prev) =>
              dayjs(prev).subtract(1, 'month').toDate(),
            )
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Touchable
          type="button"
          onClick={() => setIsMonthFilterOpen((state) => !state)}
        >
          <Text variant={'caption'} css={{ color: '$brand_primary_pure' }}>
            {dayjs(props.calendarDate).format('MMM')}
          </Text>
        </Touchable>
        <IconButton
          type="button"
          onClick={() => {
            props.setCalendarDate((prev) =>
              dayjs(prev).add(1, 'month').toDate(),
            )
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {isMonthFilterOpen && (
          <OptionsContainer ref={monthContainerRef}>
            {months.map((month) => (
              <Button
                type="button"
                css={{ width: '100%', marginBottom: '$2' }}
                variant="secondary"
                key={month.number}
                onClick={() => {
                  props.setCalendarDate((prev) =>
                    dayjs(prev).month(month.jsNumber).toDate(),
                  )
                  setIsMonthFilterOpen(false)
                }}
              >
                {month.name}
              </Button>
            ))}
          </OptionsContainer>
        )}
      </Filter>
      <Filter>
        <IconButton
          type="button"
          onClick={() => {
            props.setCalendarDate((prev) =>
              dayjs(prev).subtract(1, 'year').toDate(),
            )
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Touchable
          type="button"
          onClick={() => setIsYearFilterOpen((state) => !state)}
        >
          <Text variant={'caption'}>
            {dayjs(props.calendarDate).format('YYYY')}
          </Text>
        </Touchable>
        <IconButton
          type="button"
          onClick={() => {
            props.setCalendarDate((prev) => dayjs(prev).add(1, 'year').toDate())
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {isYearFilterOpen && (
          <OptionsContainer ref={yearContainerRef}>
            {allYearsOptions.map((year) => (
              <Button
                type="button"
                css={{ width: '100%', marginBottom: '$2' }}
                variant="secondary"
                key={year.value}
                onClick={() => {
                  props.setCalendarDate((prev) =>
                    dayjs(prev).year(parseInt(year.value)).toDate(),
                  )
                  setIsYearFilterOpen(false)
                }}
              >
                {year.label}
              </Button>
            ))}
          </OptionsContainer>
        )}
      </Filter>
      <Filter>
        <Touchable
          type="button"
          onClick={() => props.setCalendarDate(dayjs().toDate())}
        >
          <Text variant={'caption'}>Hoy</Text>
        </Touchable>
      </Filter>
    </FiltersContainer>
  )
}

export const FiltersContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '$md',
  borderTopRightRadius: '$md',
  minWidth: 250,
})

export const IconButton = styled('button', {
  all: 'unset',
  height: 24,
  width: 24,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    height: 20,
    width: 20,
  },

  cursor: 'pointer',
})

export const Filter = styled('div', {
  height: '$8',
  paddingTop: '$1',
  paddingBottom: '$1',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  background: '$interface_light_up',

  // borderRadius: '$md',

  // '& button': {
  //   all: 'unset',
  //   cursor: 'pointer',
  // },

  '& svg': {
    height: '$6',
    width: '$6',
    color: '$brand_primary_pure',
    strokeWidth: 2,
  },

  [`& ${Text}`]: {
    color: '$brand_primary_pure',
    fontWeight: '$bold',
    padding: '0 $2',
  },
})

export const OptionsContainer = styled('div', {
  position: 'absolute',
  top: 48,
  width: 150,
  right: 0,
  left: 0,
  maxHeight: 150,
  overflowY: 'scroll',
  background: '$interface_light_pure',
  zIndex: 9999,
})

export const Option = styled('div', {})
