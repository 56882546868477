import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { ICompleteWorkerEnvelope } from '../../types/worker'

const fetchWorker = async (id: string) => {
  try {
    const response = await apiV2.get<ICompleteWorkerEnvelope>(
      `/user/get-user/${id}`,
    )

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const useWorker = (id: string) => {
  const query = useQuery(
    ['workers', id],
    async () => {
      const workers = await fetchWorker(id)

      return workers
    },
    {
      enabled: !!id,
    },
  )

  return query
}
