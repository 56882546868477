import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import { SmartShift } from '@/pages/turnos/components/SmartShiftManagement/types'

const fetchShifts = async (withDeleted = false) => {
  const response = await apiV2.get<SmartShift[]>(
    `/shift/list-shift?withDeleted=${withDeleted}`,
  )

  return response.data
}

export const useSmartShifts = (props?: { withDeleted?: boolean }) => {
  const { data: me } = useMe()

  const query = useQuery(
    ['shifts', me?.company_id, props?.withDeleted],
    () => fetchShifts(props?.withDeleted),
    {
      keepPreviousData: true,
      enabled: !!me && !!me?.company_id,
      retry: false,
    },
  )
  return query
}
