import { useEffect, useMemo } from 'react'
import { ExportTemplateFields, ExportTemplateSchemaType } from './form'
import { ITemplate } from '@/libs/react-query/types/reports'
import { months } from '@/utils/date'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAvailableCells, usePayrolls } from '@/libs/react-query/hooks'
import {
  useExportTemplateDateForm,
  yearsOptions,
} from './useExportTemplateDateForm'
import { useAguinaldos } from '@/libs/react-query/hooks/useAguinaldos/useAguinaldos'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import dayjs from 'dayjs'

export const useExportTemplateConfiguration = (
  template: ITemplate | undefined,
  prefix?: string,
) => {
  const name = prefix ? `${prefix}.` : ''
  const methods = useFormContext<ExportTemplateSchemaType>()

  const [year] = useWatch({
    control: methods.control,
    name: [`${name}year`] as ['year'],
  })

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payroll')),
  )

  const { data: payrolls } = usePayrolls()
  const { data: aguinaldos } = useAguinaldos({
    cellsIds: availableCells?.map((v) => v.id) || [],
  })

  const selectedYear = yearsOptions.find((m) => +m.value === +year)

  const { data: dateFormByType } = useExportTemplateDateForm({
    selectedYear,
    prefix,
  })

  const necessaryDataForm: ExportTemplateFields = useMemo(() => {
    const formFields: ExportTemplateFields = []

    if (template?.id.includes('aguinaldo-report')) {
      return formFields
    }

    if (template?.type === 'single') {
      if (template.row === 'day') {
        formFields.push(...dateFormByType.days)
      } else {
        formFields.push(...dateFormByType[template.row])
      }
    }

    if (template?.type === 'collective') {
      if (template.row === 'workers') {
        if (template.rowDetails === 'day') {
          formFields.push(...dateFormByType.day)
        } else if (template.rowDetails === 'month') {
          formFields.push(...dateFormByType.workersMonthly)
        } else {
          formFields.push(...dateFormByType.year)
        }
      } else {
        formFields.push(...dateFormByType[template.row])
      }
    }

    if (template?.type === 'totals') {
      formFields.push(...dateFormByType[template.row])
    }

    return formFields
  }, [template, dateFormByType])

  const payrollForm = useMemo(() => {
    const formFields: ExportTemplateFields = []

    const hasPayrollOrSueldosColumns = template?.columns.find(
      (c) => c.type === 'payrolls' || c.type === 'sueldos',
    )

    const hasPayrollFields =
      hasPayrollOrSueldosColumns ||
      template?.id.includes('sueldos') ||
      template?.id === 'default:general-workers-status-summary' ||
      template?.id.includes('bank-payroll')

    if (!hasPayrollFields) {
      return []
    }

    const onePayrollForm: ExportTemplateFields = [
      {
        name: `${name}payrollIds.0` as 'payrollIds.0',
        _type: 'dropdown',
        label: 'Nómina',
        options:
          payrolls?.map((p) => ({
            label: p.name,
            value: p.id,
          })) || [],
        // defaultOption: {
        //   label: payrolls?.[0]?.name || '',
        //   value: payrolls?.[0]?.id || '',
        // },
      },
    ]

    const twelvePayrollsForm: ExportTemplateFields = Array.from(
      { length: 12 },
      (_, i) => {
        return {
          name: `${name}payrollIds.${i}`,
          _type: 'dropdown' as const,
          label: `Nómina ${months.find((m) => m.number === i + 1)?.name}`,
          width: 200,
          options:
            payrolls?.map((p) => ({
              label: p.name,
              value: p.id,
            })) || [],
          // defaultOption: {
          //   label: payrolls?.[i]?.name || '',
          //   value: payrolls?.[i]?.id || '',
          // },
        } as ControlledFieldProps<ExportTemplateSchemaType>
      },
    ).flatMap((x) => x)

    if (template?.type === 'single') {
      if (template.row === 'day') {
        formFields.push(...onePayrollForm)
      } else if (template.row === 'month') {
        formFields.push(...twelvePayrollsForm)
      } else if (template.row === 'year') {
        formFields.push(...twelvePayrollsForm)
      }
    }

    if (template?.type === 'collective') {
      if (template.row === 'workers') {
        if (template.rowDetails === 'month') {
          formFields.push(...onePayrollForm)
        } else if (template.rowDetails === 'year') {
          formFields.push(...twelvePayrollsForm)
        }
      } else if (template.row === 'sectors') {
        formFields.push(...onePayrollForm)
      }
    }

    if (template?.type === 'totals') {
      if (template.row === 'month') {
        formFields.push(...twelvePayrollsForm)
      } else if (template.row === 'year') {
        formFields.push(...twelvePayrollsForm)
      }
    }

    if (template?.type === 'none') {
      if (template?.id.includes('bank-payroll')) {
        formFields.push(...onePayrollForm)
      }
    }
    return formFields
  }, [template, payrolls, prefix])

  const aguinaldoForm = useMemo(() => {
    const hasAguinaldoFields =
      template?.columns.find((c) => c.name === 'aguinaldo_value') ||
      template?.id.includes('sueldos') ||
      template?.id.includes('aguinaldo-report')

    if (!hasAguinaldoFields) {
      return []
    }

    const onePayrollForm: ExportTemplateFields = [
      {
        name: `${name}aguinaldoIds.0` as 'aguinaldoIds.0',
        _type: 'dropdown',
        label: 'Aguinaldo',
        options:
          aguinaldos?.map((p) => ({
            label: p.name,
            value: p.id,
          })) || [],
        defaultOption: {
          label: aguinaldos?.[0]?.name || '',
          value: aguinaldos?.[0]?.id || '',
        },
      },
    ]

    return onePayrollForm
  }, [template, aguinaldos, prefix])

  useEffect(() => {
    if (necessaryDataForm.length) {
      const isRangedDate = necessaryDataForm.find(
        (f) => f._type === 'calendar' && f.calendarType === 'range',
      )

      if (isRangedDate) {
        methods.setValue('dates', [
          dayjs().startOf('month').toDate(),
          dayjs().endOf('month').toDate(),
        ])

        return
      }

      const isSingleDate = necessaryDataForm.find(
        (f) => f._type === 'calendar' && f.calendarType === 'single',
      )

      if (isSingleDate) {
        methods.setValue('day', [dayjs().startOf('day').toDate()])
      }
    }
  }, [necessaryDataForm])

  return {
    data: necessaryDataForm,
    payrollForm,
    aguinaldoForm,
  }
}
