import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IOrganogramInfos } from '../../types/organogram'
import { useMe } from '../useMe'

const fetchOrganogram = async () => {
  const response = await apiV2.get<IOrganogramInfos>(
    '/permission/get-organogram',
  )
  return response.data
}

export const useOrganogram = () => {
  const { data: user } = useMe()

  const query = useQuery(
    ['organogram', user?.company_id],
    () => fetchOrganogram(),
    {
      enabled: !!user && !!user.company_id,
      retry: false,
    },
  )
  return query
}
