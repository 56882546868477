import * as Popover from '@radix-ui/react-popover'
import { Div } from '../Div'
import { Text, Tooltip, keyframes, styled } from '@punto-ui/react'
import React, { useRef, useState } from 'react'
import { Avatar } from '../Avatar'

interface IVisibilityWorker {
  name: string
  id: string
  image_url: string
}

interface IWorkersVisibility {
  users: IVisibilityWorker[]
  nameWidth?: number
}

interface WorkerVisibilityContainerProps {
  users: IVisibilityWorker[]
  children: React.ReactNode
}

const UserInfosDropdown = ({ users }: { users: IVisibilityWorker[] }) => {
  return (
    <Popover.Portal>
      <Popover.Content sideOffset={12}>
        <PopoverContent>
          <Div
            css={{
              padding: '$2',
              paddingBottom: 0,
            }}
          >
            {users.map((user) => (
              <Div
                key={user.id}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '$2',
                }}
              >
                <Avatar
                  alt={user.name}
                  src={user.image_url || ''}
                  height={AVATAR_SIZE}
                  width={AVATAR_SIZE}
                />
                <Text css={{ marginLeft: '$2' }} variant="caption">
                  {user.name}
                </Text>
              </Div>
            ))}
          </Div>
        </PopoverContent>
      </Popover.Content>
    </Popover.Portal>
  )
}

const AvatarContainer = ({
  children,
  users,
}: WorkerVisibilityContainerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Div>
      <Popover.Root
        open={isOpen}
        onOpenChange={(b) => {
          console.log('closing')
          setIsOpen(b)
        }}
        modal
      >
        <Div>
          <Popover.Trigger asChild>{children}</Popover.Trigger>
          <AvatarContainerDiv>
            <UserInfosDropdown users={users} />
          </AvatarContainerDiv>
        </Div>
      </Popover.Root>
    </Div>
  )
}

const AvatarContainerDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const AVATAR_SIZE = 20

export const WorkersVisibilitySmall = ({
  users,
  nameWidth,
}: IWorkersVisibility) => {
  const textRef = useRef<HTMLDivElement>(null)

  const hasOne = users.length === 1
  const hasNone = users.length === 0

  const nameFullWidth = nameWidth || 100

  if (hasNone) {
    return (
      <Div>
        <Text variant="caption">Nenhum colaborador</Text>
      </Div>
    )
  }

  const firstUser = users[0]
  const nameIsOverflowing = textRef.current
    ? textRef.current.scrollWidth > textRef.current.clientWidth
    : false

  if (hasOne) {
    return (
      <AvatarContainer users={users}>
        <Div
          key={firstUser.id}
          css={{
            marginLeft: 0,
            display: 'flex',
            gap: '$2',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={firstUser.image_url || ''}
            height={AVATAR_SIZE}
            width={AVATAR_SIZE}
            alt={firstUser.name}
            unoptimized={true}
          />
          <Div
            css={{
              height: AVATAR_SIZE,
              width: AVATAR_SIZE,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              background: 'transparent',
              borderRadius: '$full',
              marginLeft: -16,
            }}
          />
          <Tooltip message={nameIsOverflowing ? firstUser.name : ''} arrow>
            <Text
              ref={textRef}
              variant="caption"
              css={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: nameFullWidth,
                textAlign: 'left',
              }}
            >
              {firstUser.name}
            </Text>
          </Tooltip>
        </Div>
      </AvatarContainer>
    )
  }

  return (
    <AvatarContainer users={users}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$2',
        }}
      >
        <Avatar
          src={firstUser.image_url || ''}
          height={AVATAR_SIZE}
          width={AVATAR_SIZE}
          alt={firstUser.name}
          unoptimized={true}
          css={{ border: '2px solid black' }}
        />
        <Div
          css={{
            height: AVATAR_SIZE,
            width: AVATAR_SIZE,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            background: '$interface_dark_deep',
            borderRadius: '$full',
            marginLeft: -16,
          }}
        >
          <Text css={{ color: '$interface_light_deep' }} variant={'caption'}>
            +{users.length - 1}
          </Text>
        </Div>
        <Tooltip message={nameIsOverflowing ? firstUser.name : ''} arrow>
          <Text
            variant="caption"
            ref={textRef}
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: nameFullWidth,
              textAlign: 'left',
            }}
          >
            {firstUser.name}
          </Text>
        </Tooltip>
      </Div>
    </AvatarContainer>
  )
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-12px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(12px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const PopoverContent = styled('div', {
  borderRadius: '$sm',
  maxHeight: 300,
  border: '1px solid $interface_light_down',
  overflowY: 'auto',
  backgroundColor: '$interface_light_pure',
  // boxShadow:
  //   'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  boxShadow: '0px 0px 16px 0px #343A4014',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
})
