import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Div } from '../Div'
import { Button, styled, Text } from '@punto-ui/react'

export const Table = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
})
export const SimpleTableHead = styled('thead')
export const SimpleTableBody = styled('tbody')
export const SimpleTableRow = styled('tr', {
  height: 40,
})
export const SimpleTableCell = styled('td', {
  height: 40,
})

export type SimpleTableColumn = {
  key: string
  header: string
  width?: number
  align?: 'left' | 'center' | 'right'
  render: (item: any) => React.ReactNode
}

export type SimpleTableProps = {
  columns: SimpleTableColumn[]
  data: any[]

  page?: number
  setPage?: (page: number) => void
  totalPages?: number
  totalItems?: number

  minWidth?: string | number
  maxHeight?: string | number
}

export const SimpleTable = ({
  columns,
  data,
  page,
  setPage,
  totalPages,
  totalItems,
  minWidth,
  maxHeight,
}: SimpleTableProps) => {
  return (
    <Div
      css={{
        background: '$interface_light_pure',
        borderRadius: '$lg',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        css={{
          maxHeight: maxHeight || 'calc(100vh - 160px)',
          overflow: 'auto',
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',

            backgroundColor: '$interface_light_pure',
            borderRadius: '$lg',

            minWidth: minWidth || 1000,
          }}
        >
          <Table>
            <SimpleTableHead>
              <SimpleTableRow
                css={{
                  backgroundColor: 'transparent',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}
              >
                {columns.map((column, index) => (
                  <SimpleTableCell
                    key={column.key}
                    width={column.width}
                    align={
                      column.align as 'left' | 'right' | 'center' | 'justify'
                    }
                    css={{
                      backgroundColor: '$interface_light_pure',
                      borderBottom: '1px solid',
                      borderColor: '$interface_light_down',

                      paddingLeft: index === 0 ? '$4' : undefined,
                      paddingRight:
                        index === columns.length - 1 ? '$4' : undefined,
                    }}
                  >
                    <Text variant="caption">{column.header}</Text>
                  </SimpleTableCell>
                ))}
              </SimpleTableRow>
            </SimpleTableHead>
            <SimpleTableBody>
              {data?.map((item, entityIndex) => (
                <SimpleTableRow
                  key={item.id}
                  css={{
                    backgroundColor:
                      entityIndex % 2 === 0
                        ? '$interface_light_up'
                        : '$interface_light_pure',

                    '&:hover': {
                      backgroundColor: '$interface_light_down',
                    },
                  }}
                >
                  {columns.map((column, index) => (
                    <SimpleTableCell
                      key={column.key}
                      width={column.width}
                      align={
                        column.align as 'left' | 'right' | 'center' | 'justify'
                      }
                      css={{
                        paddingLeft: index === 0 ? '$4' : undefined,
                        paddingRight:
                          index === columns.length - 1 ? '$4' : undefined,
                      }}
                    >
                      {column.render?.(item)}
                    </SimpleTableCell>
                  ))}
                </SimpleTableRow>
              ))}
            </SimpleTableBody>
          </Table>
        </Div>
      </Div>
      {page !== undefined && setPage !== undefined && (
        <Div
          css={{
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            paddingLeft: '$4',
            paddingRight: '$4',

            borderTop: '1px solid',
            borderColor: '$interface_light_down',

            gap: '$2',
          }}
        >
          <Div css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
            <Button
              variant="secondary"
              size="sm"
              disabled={page === 1}
              onClick={() => {
                setPage(page - 1)
              }}
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              disabled={page === totalPages || !totalPages}
              onClick={() => {
                setPage(page + 1)
              }}
            >
              <ChevronRightIcon />
            </Button>
            <Text variant="description">
              Página {page} de {totalPages}
            </Text>
          </Div>
          <Div>
            <Text variant="description">{totalItems} movimientos</Text>
          </Div>
        </Div>
      )}
    </Div>
  )
}
