import { IWorkerUser } from '../worker'
import { IDefaultApiPagination } from '../pagination'
import { IDefaultModuleContextPropsV2 } from '../context'
import { IAuthorizationStatus, RequestStatus } from '../requests'

export const movementOptions = [
  {
    label: 'Presencia',
    value: 'presence',
  },
  {
    label: 'Falta',
    value: 'absence',
  },
  // {
  //   label: 'Desvinculación',
  //   value: 'resignation',
  // },
  {
    label: 'Suspensión',
    value: 'suspension',
  },
  // {
  //   label: 'Vacaciones',
  //   value: 'vacation',
  // },
  // {
  //   label: 'Contratación',
  //   value: 'contract',
  // },
  {
    label: 'Amonestación',
    value: 'amonest',
  },
  {
    label: 'Apercibimiento',
    value: 'atention',
  },
  {
    label: 'Permiso',
    value: 'permission',
  },
  {
    label: 'Preaviso',
    value: 'preWarning',
  },
  {
    label: 'Accidentes laborales',
    value: 'accident',
  },
  {
    label: 'Abandono',
    value: 'abandonment',
  },
  {
    label: 'Sanciones Judiciales',
    value: 'judicial',
  },
  {
    label: 'Enfermedades',
    value: 'diseases',
  },
  {
    label: 'Licencia de maternidad',
    value: 'maternity',
  },
  {
    label: 'Licencia de paternidad',
    value: 'paternity',
  },
]

export const MovementCategoriesArray = [
  'all',
  'presence',
  'absence',
  // 'resignation',
  'suspension',
  // 'vacation',
  // 'contract',
  'amonest',
  'atention',
  'permission',
  'preWarning',
  'accident',
  'abandonment',
  'judicial',
  'diseases',
  'maternity',
  'paternity',
] as const

export const MovementTypesArray = [
  'MULTIPLE_DAYS',
  'SINGLE_DAY',
  'SINGLE_PERIOD',
] as const

export type IMovementCategories = (typeof MovementCategoriesArray)[number]
export type IMovementTypes = (typeof MovementTypesArray)[number]

export const MovementLabelsArray = [
  'Todos os tipos',
  // 'Desvinculación',
  'Falta',
  // 'Contratación',
  // 'Vacaciones',
  'Amonestación',
  'Apercibimiento',
  'Permiso',
  'Preaviso',
  'Accidentes laborales',
  'Suspensión',
  'Abandono',
  'Sanciones Judiciales',
  'Enfermedades',
  'Licencia de maternidad',
  'Licencia de paternidad',
  'Presencia',
]

export type IMovementLabels = (typeof MovementLabelsArray)[number]

export const movementsDictionary: Record<IMovementCategories, IMovementLabels> =
  {
    all: 'Todos os tipos',
    // resignation: 'Desvinculación',
    // contract: 'Contratación',
    absence: 'Falta',
    // vacation: 'Vacaciones',
    amonest: 'Amonestación',
    atention: 'Apercibimiento',
    permission: 'Permiso',
    preWarning: 'Preaviso',
    accident: 'Accidentes laborales',
    suspension: 'Suspensión',
    abandonment: 'Abandono',
    judicial: 'Sanciones Judiciales',
    diseases: 'Enfermedades',
    maternity: 'Licencia de maternidad',
    paternity: 'Licencia de paternidad',
    presence: 'Presencia',
  } as const

export const positiveMovementsDictionary = [
  'presence',
  'maternity',
  'paternity',
  'permission',
]
export const warningMovementsDictionary = [
  'preWarning',
  'atention',
  'amonest',
  'accident',
]
export const negativeMovementsDictionary = [
  'absence',
  'abandonment',
  'judicial',
  'diseases',
  'suspension',
]

export interface IPeriod {
  id: string
  identifier_id: string
  movement_id: string
  date: string
  period_start: any
  period_end: any
  created_at: string
  updated_at: string
  deleted_at: any
}

interface UserMovement {
  id: string
  identifier_id: string
  user_id: string
  movement_id: string
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export interface IMovementV2 {
  id: string
  identifier_id: string
  batch_id: any
  type: IMovementTypes
  category: IMovementCategories
  title: string
  description: string
  should_pay_period: boolean
  should_include_in_ips: boolean
  should_include_hours: boolean
  created_at: string
  updated_at: string
  deleted_at: any
  periods: IPeriod[]
  users?: UserMovement[]
  status: RequestStatus
}

export interface IMovementEnvelope {
  movement: IMovementV2
  users: IWorkerUser[]
  authorizations: IAuthorizationStatus[]
  canAuthorize: boolean
}

export type IPaginatedMovements = IDefaultApiPagination<IMovementEnvelope>
export type IMovementContextProps = IDefaultModuleContextPropsV2
