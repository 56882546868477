import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { ControlledCalendarP } from './types'
import dayjs from 'dayjs'
import { getDeepVal } from '@/utils/object'
import { InputDatePicker } from '@/components/InputDatePicker'
import { InputDatePickerV2 } from '@/components/InputDatePickerV2'

export const ControlledCalendarInput = <TFieldValues extends FieldValues>({
  calendarType,
  onValueChange,
  InputComponent,
  ...props
}: ControlledCalendarP<TFieldValues>) => {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext()

  const value = useWatch({
    name: props.name,
    control,
    defaultValue: getValues(props.name),
  })

  const hoursEnabled = useWatch({
    name: props.hourName || '',
    control,
  })

  const handleOnChange = (dates: (Date | null)[]) => {
    const filteredValidDates = dates.filter(
      (d) => d && dayjs(d).isValid(),
    ) as Date[]

    setValue(props.name, filteredValidDates as any)
    onValueChange?.(filteredValidDates as any)
  }

  const handleToggleHour = (v: boolean) => {
    if (props.hourName) {
      setValue(props.hourName, v as any)
    }
  }

  const handleCalendarTypeChange = (v: 'multiple' | 'range' | 'single') => {
    if (props.typeName) {
      setValue(props.typeName, v as any)
    }
  }

  const inputError = getDeepVal(errors, props.name)

  if (props.v2) {
    return (
      <InputDatePickerV2
        InputComponent={InputComponent}
        {...props}
        errorMessage={inputError ? inputError.message : undefined}
        type={calendarType}
        dates={value}
        onChangeDates={handleOnChange}
        isHoursEnabled={hoursEnabled}
        onChangeHoursEnabled={handleToggleHour}
        onCalendarTypeChange={handleCalendarTypeChange}
      />
    )
  }

  return (
    <InputDatePicker
      InputComponent={InputComponent}
      {...props}
      errorMessage={inputError ? inputError.message : undefined}
      hoursEnabled={hoursEnabled}
      type={calendarType}
      multipleDates={calendarType === 'multiple' ? value : undefined}
      onChange={handleOnChange}
      onToggleHours={handleToggleHour}
      firstDate={value?.[0] ?? props?.defaultValues?.[0]}
      secondDate={value?.[1] ?? props?.defaultValues?.[1]}
    />
  )
}
