import { useMemo } from 'react'
import { ExportTemplateFields } from './form'

export const yearsOptions = Array.from({ length: 10 }, (_, i) => ({
  label: (new Date().getFullYear() - i).toString(),
  value: (new Date().getFullYear() - i).toString(),
}))

export const useExportTemplateDateForm = ({
  selectedYear,
  prefix,
}: {
  prefix?: string
  selectedYear?: {
    label: string
    value: string
  }
}) => {
  const name = prefix ? `${prefix}.` : ''
  const data = useMemo(() => {
    const monthAndYearForm: ExportTemplateFields = [
      {
        name: `${name}dates` as 'dates',
        _type: 'calendar',
        label: 'Rango de tiempo del reporte',
        calendarType: 'range',
        maxDates: 31,
      },
    ]

    const dateFormByType: Record<string, ExportTemplateFields> = {
      day: [
        {
          name: `${name}day` as 'day',
          _type: 'calendar',
          label: 'Fecha del reporte',
          calendarType: 'single',
          maxDates: 999,
        },
      ],
      year: [
        {
          name: `${name}year` as 'year',
          _type: 'dropdown',
          label: 'Año del reporte',
          options: yearsOptions,
          defaultOption: {
            label: selectedYear?.label || '',
            value: selectedYear?.value || '',
          },
        },
      ],
      month: [
        {
          name: `${name}year` as 'year',
          _type: 'dropdown',
          label: 'Año del reporte',
          options: yearsOptions,
          defaultOption: {
            label: selectedYear?.label || '',
            value: selectedYear?.value || '',
          },
        },
      ],
      days: monthAndYearForm,
      workersMonthly: monthAndYearForm,
      months: [
        {
          name: `${name}year` as 'year',
          _type: 'dropdown',
          label: 'Mes del reporte',
          options: yearsOptions,
          defaultOption: {
            label: selectedYear?.label || '',
            value: selectedYear?.value || '',
          },
        },
      ],
      sectors: monthAndYearForm,
      entities: [
        {
          name: `${name}dates` as 'dates',
          _type: 'calendar',
          label: 'Rango de tiempo del reporte',
          calendarType: 'range',
          maxDates: 90,
        },
      ],
      none: [],
    }

    return dateFormByType
  }, [selectedYear, prefix])

  return {
    data,
  }
}
